import React, { Component } from 'react';

class Modal extends Component {

    constructor(props) {
        super();
        this.state = {
            open: props.open ? true : false
        }
    }


    openModal() {
        this.setState({
            open: true
        }, () => {
            document.body.classList.add('overflow-hidden');
        })
    }

    addOnCloseListener(closeListener){
        this.closeListener = closeListener;
    }

    closeModal(e) {
        if(e?.target){
            if(!e.target.className || !e.target.className.includes('modal_background')){
                return
            }
        }
        if(this.closeListener) this.closeListener()
        this.setState({
            open: false
        }, () => {
            document.body.classList.remove('overflow-hidden');
        })
    }

    render() {
        return (

            this.state.open && 
            <div className='modal_background-container' onClick={this.closeModal.bind(this)}>
                <div className='modal_background' onClick={this.closeModal.bind(this)}>
                    <div style={this.props.styles} className={`modal_container ${this.state.open ? '' : 'hidden'}`}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;
