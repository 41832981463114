import { sendGet } from "../../helpers";

const startQuery = (tags, ignored, sorting, minScore, maxScore, minVideoTime, count, offset) => {
    return sendGet(`/p?tags=${tags} ${ignored} ${sorting} ${minScore > 0 ? `score:>=${minScore}` : ''} ${maxScore > 0 ? `score:<=${maxScore}` : ''}&minVideoTime=${minVideoTime}&count=${count}&offset=${offset}`)
}

const fetchTags = (tag) => {
    return sendGet(`/t?tag=${tag}`)
}


export const Services = {
    fetchTags,
    startQuery
}
