import React, { Component } from 'react';
import { sleep, distanceBetweenPoints } from '../../helpers';


let stars = [];
let explosions = [];

class Entity {

    x; y; r; vecX; vecY;
    constructor(x, y, r, vecX, vecY) {
        this.x = x;
        this.y = y;
        this.r = r;
        this.vecX = vecX;
        this.vecY = vecY;
    }

    update() {
        this.x += this.vecX;
        this.y += this.vecY;
        if (this.x > window.innerWidth) this.x -= window.innerWidth;
        if (this.y > window.innerHeight) this.y -= window.innerHeight;
        if (this.x < 0) this.x += window.innerWidth + this.r;
        if (this.y < 0) this.y += window.innerHeight + this.r;
    }

}

class Explosion extends Entity {

    maxRadius = 200;
    maxPower = 6;

    update() {
        if(this.r < this.maxRadius){
            this.r++;
        }else{
            for(let i = explosions.length-1;i >= 0;i--){
                if(explosions[i] == this) explosions.splice(i, 1);
            }
        }
        for(let star of stars){
            let dist = distanceBetweenPoints(this.x, this.y, star.x, star.y);
            if(dist <= this.r){
                let distanceX = star.x - this.x;
                let distanceY = star.y - this.y;
                distanceX /= this.maxRadius;
                distanceY /= this.maxRadius;
                star.vecX = this.maxPower * (distanceX)
                star.vecY = this.maxPower * (distanceY)
            }
        }
    }
}

class LoginCanvas extends Component {

    starCount = 50;
    fps = 0;

    
    explosionRadius = 20;

    full360 = 2 * Math.PI;

    constructor(props) {
        super();
    }

    generateRandom(from, to) {
        return Math.random() * (to - from) + from;
    }

    shouldComponentUpdate() {
        return false;
    }

    handleCanvasClick(e) {
        return
        let x = e.clientX;
        let y = e.clientY;
        explosions.push(new Explosion(x , y , 0, 0, 0))
    }


    async renderCanvas() {
        let timePerFrame = 1000 / 60;
        let lastFrame = (new Date).getTime();
        let ctx = this.canvasRef.getContext("2d");
        while (true) {
            if(!this.canvasRef) return;
            this.fps++;
            this.canvasRef.width = window.innerWidth;
            this.canvasRef.height = window.innerHeight;

            var grd = ctx.createLinearGradient(0, 0, window.innerWidth, window.innerHeight);
            grd.addColorStop(0, "#11141c");
            grd.addColorStop(1, "#17191f");

            // Fill with gradient
            ctx.fillStyle = grd;
            ctx.fillRect(0, 0, window.innerWidth, window.innerHeight);
            for (let explosion of explosions) {
                explosion.update();
                ctx.beginPath();
                ctx.arc(explosion.x, explosion.y, explosion.r, 0, this.full360);
                ctx.strokeStyle = "yellow";
                ctx.stroke();
            }
            for (let star of stars) {
                star.update();
                ctx.beginPath();
                ctx.arc(star.x, star.y, star.r, 0, this.full360);
                ctx.fillStyle = "white";
                ctx.fill();
            }
            let diff = (new Date).getTime() - lastFrame;
            await sleep(timePerFrame - diff || 1);
            lastFrame = (new Date).getTime();
        }
    }

    componentDidMount() {
        for (let i = 0; i < this.starCount; i++) {
            let speed = this.generateRandom(1, 2)
            stars.push(new Entity(this.generateRandom(0, window.innerWidth), this.generateRandom(0, window.innerHeight), 3 - speed, speed, speed));
        }

        window.onclick = this.handleCanvasClick.bind(this)

        this.renderCanvas();


    }

    componentWillUnmount(){
        stars = [];
    }


    render() {
        return (
            <canvas ref={(ref) => this.canvasRef = ref} id="login_canvas" />
        );
    }
}

export default LoginCanvas;
