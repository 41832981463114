import { takeLatest, call, put } from "redux-saga/effects";
import * as _ from "lodash"


export function* getImages() {
    yield takeLatest("GET_IMAGES", getImagesAction);
}

function* getImagesAction(action) {


}