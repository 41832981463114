import {fork} from 'redux-saga/effects';
import {fetchTags, startQuery, aditionalQuery} from './redux/queryPage/saga';
import { getImages } from './redux/dashboard/saga';
import { error } from './redux/common/saga';

function *sagas() {
	yield [
		fork(fetchTags),
		fork(getImages),
		fork(error),
		fork(startQuery),
		fork(aditionalQuery)
	]
}

export {sagas};