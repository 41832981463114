import { startQuery, fetchTags } from '../../redux/queryPage/actions';

export const mapStateToProps = state => ({
    tags: state.QueryPage.tags,
    queryData: state.QueryPage.queryData
})

export const mapDispatchToProps = dispatch => ({
    fetchTags: (tag) => dispatch(fetchTags(tag)),
    startQuery: (tags, ignored, sorting, minScore, maxScore, minVideoTime, count, offset) => dispatch(startQuery(tags, ignored, sorting, minScore, maxScore, minVideoTime, count, offset)),
})