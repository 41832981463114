import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './connectFunctions';
import LoginCanvas from '../login-canvas';


class QueryPage extends Component {

	constructor() {
		super();
		this.state = {
			tags: '',
			offset: 0,
			count: window.localStorage.getItem('count') || 20,
			ignored: window.localStorage.getItem('ignored') || '',
			minScore: window.localStorage.getItem('minScore') || 0,
			maxScore: window.localStorage.getItem('maxScore') || 0,
			sorting: window.localStorage.getItem('sorting') || 'sort:score:desc',
			minVideoTime: window.localStorage.getItem('minVideoTime') || 0
		}
	}

	componentDidMount() {
		this.setState({
			...this.props.queryData,
			offset: 0
		})
	}

	startQuery(e) {
		e.preventDefault();
		this.props.startQuery(this.state.tags, this.state.ignored, this.state.sorting, this.state.minScore, this.state.maxScore, this.state.minVideoTime, this.state.count, this.state.offset);
	}

	onTagClick(tag, e) {
		let tags = this.state.tags.split(" ")
		tags[tags.length - 1] = tag
		this.setState({
			tags: tags.join(" ") + " "
		}, () => {
			this.tagRef.focus()
		})
		this.props.fetchTags("")
	}

	handleTagsChange(e) {
		let tags = e.target.value.split(" ")
		this.props.fetchTags(tags[tags.length - 1])
		this.setState({
			tags: e.target.value
		})
	}



	hangleCountChange(e) {
		window.localStorage.setItem('count', e.target.value)
		this.setState({
			count: e.target.value
		})
	}

	handleOffsetChange(e) {
		this.setState({
			offset: e.target.value
		})
	}

	handleIgnoredChange(e) {
		window.localStorage.setItem('ignored', e.target.value)
		this.setState({
			ignored: e.target.value
		})
	}

	handleMinScoreChange(e) {
		window.localStorage.setItem('minScore', e.target.value)
		this.setState({
			minScore: e.target.value
		})
	}

	handleMaxScoreChange(e) {
		window.localStorage.setItem('maxScore', e.target.value)
		this.setState({
			maxScore: e.target.value
		})
	}

	
	handleMinVideoTimeChange(e) {
		window.localStorage.setItem('minVideoTime', e.target.value)
		this.setState({
			minVideoTime: e.target.value
		})
	}

	handleSortingChange(e) {
		window.localStorage.setItem('sorting', e.target.value)
		this.setState({
			sorting: e.target.value
		})
	}

	render() {
		return (
			<>
				<LoginCanvas />
				<form id="login-form">
					<div className="logo">Crogers</div>
					<div>
						<div className="group">
							<input ref={ref => this.tagRef = ref} onChange={this.handleTagsChange.bind(this)} type="text" value={this.state.tags} required />
							<label>Tags</label>
							{this.props.tags && <div className="tags">
								{this.props.tags.map((x, i) => {
									return <div onClick={this.onTagClick.bind(this, x.value)} key={i} className="tag"><b>{x.value}</b>{` ${x.label.split(" ").slice(-1)}`}</div>
								})}
							</div>
							}
						</div>
						<div className="group">
							<input onChange={this.hangleCountChange.bind(this)} type="number" value={this.state.count} required />
							<label>Count</label>
						</div>
						<div className="group" style={{ paddingBottom: "10px" }}>
							<input onChange={this.handleOffsetChange.bind(this)} type="number" value={this.state.offset} required />
							<label>Page</label>
						</div>
						<div className="group">
							<div style={{ fontSize: "14px", paddingLeft: 4 }}>Sorting</div>
							<select onChange={this.handleSortingChange.bind(this)} defaultValue={this.state.sorting}>
								<option value="sort:score:desc">Popularity</option>
								<option value=" ">Newest</option>
							</select>
						</div>
						<div className='row'>
							<div className="group">
								<input style={{ width: "140px" }} onChange={this.handleMinScoreChange.bind(this)} type="number" value={this.state.minScore} required />
								<label>Minimal Score</label>
							</div>
							<div className="group">
								<input style={{ width: "140px" }} onChange={this.handleMaxScoreChange.bind(this)} type="number" value={this.state.maxScore} required />
								<label>Maximal Score</label>
							</div>
						</div>
						<div className="group">
							<input onChange={this.handleIgnoredChange.bind(this)} type="text" value={this.state.ignored} required />
							<label>Ignored</label>
						</div>
						<div className="group">
							<input onChange={this.handleMinVideoTimeChange.bind(this)} type="number" value={this.state.minVideoTime} required />
							<label>Minimal Video length</label>
						</div>
					</div>
					<button onClick={this.startQuery.bind(this)} className="login-button">Login</button>
				</form>
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(QueryPage);
