import { takeLatest, call, put } from "redux-saga/effects";
import { toast } from 'react-toastify';


export function* error() {
    yield takeLatest("ERROR", errorAction);
}

function* errorAction(action) {
    const { message } = action.payload;
    if(message){
        toast.error(message);
    }else{
        toast.error("Something went wrong try again later");
    }
    yield put({ type: "LOGOUT", payload: {} });

}