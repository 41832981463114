import { store } from "../.."

const QueryPage = (state = [], action) => {
    switch (action.type) {
        case 'FETCH_TAGS_SUCCESS':
            return {...state, tags: action.payload}
        case 'START_QUERY':
            return {...state, queryData: action.payload}
        case 'ADITIONAL_QUERY':
            return {...state, queryData: action.payload}
        default:
            return state
    }
}

export default QueryPage