import axios from "axios";
import config from "./cfg/config.json"

export const createAction = (type, payload) => ({ payload, type });


export function distanceBetweenPoints(x1, y1, x2, y2) {
    return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
}

export const sleep = async (ms) => {
    await new Promise((res, rej) => {
        setTimeout(() => res(), ms);
    })
}



export const piperAddress = `${config.piperHost}:${config.piperPort}`

export const sendPost = async (url, body) => {
    return await axios.post(piperAddress+url, {query: body})
}

export const sendGet = async (url) => {
    return await axios.get(piperAddress+url)
}

