import { takeLatest, call, put } from "redux-saga/effects";
import { Services } from "../dashboard/service";
import history from "../../history";


export function* executelogin() {
    yield takeLatest("EXECUTE_LOGIN", executeloginAction);

}

function* executeloginAction(action) {
    // try {
    //     const { login, password } = action.payload;
    //     let response = yield call(fetchLogin, login, password);
    //     response = response.data.data.login
    //     if(response != null){
    //         response.logged = true;
    //         yield put({ type: "EXECUTE_LOGIN_SUCCESS", payload: response });
    //         history.push("/dashboard");
    //         return;
    //     }else{
    //         toast.error("Wrong credentials!")
    //         response = {logged: false};
    //     }

    // } catch (error) {
    //     yield put({ type: "EXECUTE_LOGIN_FAILURE", error });
    // }

}




function* fetchTagsAction(action) {
    try {
        const { tag } = action.payload;
        if (tag.trim().length == 0) {
            yield put({ type: "FETCH_TAGS_SUCCESS", payload: null });
            return;
        }
        let response = yield call(Services.fetchTags, tag);
        yield put({ type: "FETCH_TAGS_SUCCESS", payload: response.data });

    } catch (error) {
        yield put({ type: "FETCH_TAGS_FAILURE", payload: error });
    }

}

export function* fetchTags() {
    yield takeLatest("FETCH_TAGS", fetchTagsAction);

}


function* startQueryAction(action) {
    try {
        const { tags, ignored, sorting, minScore, maxScore, minVideoTime, count, offset } = action.payload;

        let ignoredTags = ignored.length > 0 ? ignored.split(" ").map(x => `-${x}`).join(" ") : ''
        let response = yield call(Services.startQuery, tags, ignoredTags, sorting, minScore, maxScore, minVideoTime, count, offset);
        yield put({ type: "START_QUERY_SUCCESS", payload: response.data });
        if (action.type == "START_QUERY")
            history.push('/dashboard')

    } catch (error) {
        yield put({ type: "START_QUERY_FAILURE", payload: error });
    }

}

export function* startQuery() {
    yield takeLatest("START_QUERY", startQueryAction);

}

export function* aditionalQuery() {
    yield takeLatest("ADITIONAL_QUERY", startQueryAction);

}
