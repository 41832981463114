import { logout, startAditionalQuery } from '../../redux/queryPage/actions';
import { getImages } from '../../redux/dashboard/actions';
import { startQuery } from '../../redux/queryPage/actions';

export const mapStateToProps = state => ({
    images: state.Dashboard.images,
    info: state.Dashboard.info,
    isFetchingFiles: state.Dashboard.isFetchingFiles,
    queryData: state.QueryPage.queryData
})

export const mapDispatchToProps = dispatch => ({
    startQuery: (tags, ignored, sorting, minScore, maxScore, minVideoTime, count, offset) => dispatch(startAditionalQuery(tags, ignored, sorting, minScore, maxScore, minVideoTime, count, offset))
})